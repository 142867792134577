import { useAuthStore } from "~/store/auth";

export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore();
  const { $toast } = useNuxtApp();

  if (process.client) {
    if (!authStore.company?.mobile_verified_at) {
      $toast.error(
        "Vous devez vérifier votre numéro de téléphone mobile pour accéder à cette page."
      );
      return navigateTo("/dashboard");
    }
  }
});
